// @ts-nocheck
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { downArrow, searchIcon } from "../../assets/img";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  useProductrequestListMutation,
  useRequestListMutation,
} from "../../redux/api/api";
import React, { useEffect, useState } from "react";
import ProductList from "../../component/myProduct/ProductList";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import { useLocation } from "react-router-dom";

const ProductFilter = ({
  showPopupHander,
  addProductHandler,
  tick,
  showCartHandler,
  setBtnChange,
  btnChange,
  page,
}) => {
  // console.log(btnChange, "btnChange");
  const dispatch = useDispatch();
  const location = useLocation();
  const [listApi] = useRequestListMutation();
  const [productApi] = useProductrequestListMutation();
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("");
  const [rows, setRows] = useState(10);

  const [userList, setUserList] = useState([]);

  const getProducttaskList = (searchs, rowss, orders) => {
    setUserList([]);
    let formdata = new FormData();
    // let params = `?page=${page}`;

    formdata.append("rows", 10);

    if (searchs) {
      formdata.append("search", searchs);
    }

    if (orders) {
      formdata.append("order", orders);
    }
    setSearch(searchs);
    setOrder(orders);
    setRows(rows);
    const apiCall = btnChange === 2 ? productApi : listApi;

    dispatch(saveLoader(true));
    apiCall(formdata)
      .unwrap()
      .then((res) => {
        let temp = [];
        if (btnChange === 2) {
          let filter = res?.lists?.filter((i) => i?.presentation_ready == 1);
          filter?.map((item, ind) => {
            let obj = {
              ...item,
            };

            let projectDetail = item?.projectDetail?.[0];
            if (projectDetail?.product_sku?.id) {
              let finder = projectDetail?.product?.colorImages?.find(
                (i) => i?.color_id == projectDetail?.product_sku?.color_id
              );
              obj.display_img = finder?.image_url;
            }

            temp.push(obj);
          });
        } else {
          let filter = res?.lists?.filter(
            (i) => i?.project?.presentation_ready !== 1
          );

          filter?.map((item, ind) => {
            let obj = {
              ...item,
            };

            if (item?.project?.id) {
              let projectDetail = item?.project?.projectDetail?.[0];

              if (projectDetail?.product_sku?.id) {
                let finder = projectDetail?.product?.colorImages?.find(
                  (i) => i?.color_id == projectDetail?.product_sku?.color_id
                );
                obj.display_img = finder?.image_url;
              }
            } else {
              let project_request_details = item?.project_request_details?.[0];

              if (project_request_details?.product_sku_id?.id) {
                let finder =
                  project_request_details?.product?.colorImages?.find(
                    (i) =>
                      i?.color_id ==
                      project_request_details?.product_sku_id?.color_id
                  );
                obj.display_img = finder?.image_url;
              }
            }

            temp.push(obj);
          });
        }

        setUserList(temp);
        dispatch(saveLoader(false));
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProducttaskList(search, rows, order);
  }, [btnChange, rows, search, order]);

  useEffect(() => {
    if (location?.state?.data?.tab) {
      setBtnChange(location?.state?.data?.tab);
    }
  }, []);

  return (
    <div className="my-product w-100 w-md-95 w-xl-85 w-xxl-80 mb-5">
      {/* <div className="filter-product d-flex flex-column flex-md-row ac-jb mt-3 mt-lg-4 px-2 px-lg-3">
        <div className="d-flex ac-js mb-3 mb-md-0 flex-wrap">
          <div className="position-relative">
            <FilterAltIcon className="fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 text-dark" />
          </div>
          <div className="position-relative d-flex ac-jc ">
            <p
              className={`text-dark cust-btn f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 primary1 ps-2 pe-1  ps-lg-4 mb-0`}>
              Status
            </p>
            <div className="dropdownbox m-3">
              <select className="rm-select-arrow cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary mb-0 text-center">
                <option value="Enquiry Received">Enquiry Received</option>
                <option value="In Process">In Process</option>
                <option value="Present Pending">Present Pending</option>
                <option value="Present Approval">Present Approval</option>
                <option value="Present Rejection">Present Rejection</option>
                <option value="Design Ready">Design Ready</option>
                <option value="Design Approved">Design Approved</option>
                <option value="Design Rework">Design Rework</option>
              </select>
              <img
                className="arrow-icon"
                style={{ left: "100%" }}
                alt="icon"
                src={downArrow}
              />
            </div>
          </div>

          <div className="position-relative d-flex ac-jc">
            <p
              className={`text-dark cust-btn f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 primary1 ps-2 pe-1  ps-lg-4 mb-0`}>
              Category
            </p>
            <div className="dropdownbox">
              <select className="rm-select-arrow cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary mb-0">
                <option value="all">All</option>
                <option value="Pack">Pack</option>
                <option value="Products">Products</option>
              </select>
              <img className="arrow-icon" alt="icon" src={downArrow} />
            </div>
          </div>
        </div>
        <div className="d-flex ac-js mb-0  flex-column flex-sm-row">
          <div className="position-relative d-flex ac-jc search-icon mb-2 mb-sm-0">
            <img src={searchIcon} alt="" />
            <input
              type="text"
              className="bg-transparent border-0 ps-1 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 "
              placeholder="Search Products"
              name=""
              id=""
              onChange={(e) =>
                getProducttaskList(
                  e.target.value,
                  order,
                  
                )
              }
              value={search}
            />
          </div>

          <div className="position-relative d-flex ac-jc">
            <p
              className={`text-dark cust-btn f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 primary1 ps-2 pe-1  ps-lg-4 mb-0`}>
              Sort By
            </p>
            <select className="cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary mb-0">
              <option value="Newest">Newest</option>
              <option value="Oldest">Oldest</option>
            </select>
          </div>
        </div>
      </div> */}
      <ProductList
        setSearch={setSearch}
        setOrder={setOrder}
        setRows={setRows}
        rows={rows}
        search={search}
        order={order}
        setUserList={setUserList}
        userList={userList}
        page={page}
        btnChange={btnChange}
      />
    </div>
  );
};

export default ProductFilter;

import React from "react";
import { dashboard, listIcon } from "../../assets/img";
import { useNavigate } from "react-router-dom";

const Sustainability = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  return (
    <div style={{ margin: "80px auto" }} className="improsive-feature w-70">
      <div className="rit-improsive-feature">
        <img src={dashboard} alt="icon" />
      </div>
      <div className="left-improsive-feature">
        <h4>All-in-one Dashboard</h4>
        <div className="">
          <div className="">
            <p style={{ color: "#839dd1" }}>
              Our dashboard helps you seamlessly track your merchandise orders.
              The dashboard provides you with the details of ALL your merch from
              the HQ, right from designs of your previous orders to inventory
              and tracking.
            </p>
            <p style={{ color: "#839dd1", marginTop: "20px" }}>
              It’s your window into all things merch!.
            </p>
          </div>
        </div>
        {token ? (
          ""
        ) : (
          <div className="d-flex ">
            <button
              className="cust-btn get-start-btn bg-btn-blue m-0 mb-4 text-nowrap"
              onClick={() => navigate("/signup")}
            >
              {/* <a className="get-start-btn" href="#/"> */}
              Create your account
              {/* </a> */}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sustainability;

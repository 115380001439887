import React, { useEffect, useState } from "react";
import FaqBanner from "../component/Company/FAQ/FaqBanner";
import FaQuestion from "../component/Company/FAQ/FaQuestion";
import VisitHelp from "../component/Company/FAQ/VisitHelp";
import Footer from "../navbar/Footer";
import { useUserBannerMutation } from "../redux/api/api";
import { useLocation } from "react-router-dom";

const FaqScreen = () => {
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");

  // RTK QUERY
  const [qafListApi] = useUserBannerMutation();

  // GET BANNERS
  const getQafList = (type) => {
    console.log(type, "search");
    const formdata = new FormData();
    formdata.append("type", "4");
    if (type) {
      formdata.append("search", type);
    }
    qafListApi(formdata)
      // .unwrap()
      .then((res) => {
        console.log(res, "resfaq");
        let temp = [];
        res?.data?.lists?.map((item, ind) => {
          let jsons = JSON.parse(item?.contents);
          if (jsons?.name) {
            temp.push({
              ...item,
              ...jsons,
              // name:jsons?.name,
              // link:jsons?.link,
              // description:jsons?.description,
              // priority:jsons?.priority,
              // button_name:jsons?.button_name,
              // image:jsons?.image
            });
          }
        });
        console.log(temp, "temp");
        temp?.sort((a, b) => a.priority - b.priority);
        setList(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    getQafList();
  }, []);
  return (
    <div className="freq-tl">
      <FaqBanner />
      {/* <VisitHelp /> */}
      <FaQuestion
        list={list}
        search={search}
        setSearch={setSearch}
        getQafList={getQafList}
      />
      <Footer />
    </div>
  );
};

export default FaqScreen;

import React, { useEffect, useState } from "react";
import ProductBreadCrumb from "../component/MyProductDetail/ProductBreadCrumb";
import ProductHeader from "../component/MyProductDetail/ProductHeader";
import ProductDetail from "../component/MyProductDetail/ProductDetail";
import ProductZoom from "../component/MyProductDetail/ProductZoom";
import { ProductColor } from "../component/MyProductDetail/ProductColor";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DeletePopup from "../component/Popup/DeletePopup";
import ReasonPopup from "../component/Popup/ReasonPopup";
import {
  useCreateorderMutation,
  useViewApproveprojectMutation,
  useViewPriductDetailsMutation,
  useViewProjectDeleteMutation,
  useViewProjectProductMutation,
  useViewProjectProductStatusMutation,
} from "../redux/api/api";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { noLogo } from "../assets/img";
import ProductPopupComponentDecline from "../component/MyProductDetail/ProductPopupComponentDecline";

const MyProjectProduct = () => {
  const location = useLocation();
  const products = location?.state;
  const type = products?.project;
  const ordertyp = products?.type;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showReason, setShowReason] = useState(false);
  const [list, setList] = useState({});
  const [pendingitem, setPendingItem] = useState({});
  const [artwrk, setArtWork] = useState("");
  const [orders, setOrders] = useState(false);
  const [artId, setArtId] = useState("");
  const [closing_date, setclosing_date] = useState([]);

  const [productViewApi] = useViewPriductDetailsMutation();
  const [projectproductViewApi] = useViewProjectProductMutation();
  const [approveViewApi] = useCreateorderMutation();
  const [deleteViewApi] = useViewProjectDeleteMutation();
  const [statusChangeApi] = useViewProjectProductStatusMutation();

  const getApprove = (status) => {
    const formdata = new FormData();
    formdata.append("project_detail_id", pendingitem?.id);
    formdata.append("artwork_status", status);
    dispatch(saveLoader(true));
    statusChangeApi(formdata)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getProduct();
        dispatch(saveLoader(false));
      })
      .catch((err) => {
        console.log(err, "err");
        dispatch(saveLoader(false));
      });
  };

  const getDelete = (reasons) => {
    const formdata = new FormData();
    if (type?.id) {
      formdata.append("project_id", type?.id);
    } else {
      formdata.append("project_id", location?.state?.id);
    }

    formdata.append("project_detail_id", pendingitem?.id);
    formdata.append("user_comment", reasons);
    formdata.append("status", "4");

    dispatch(saveLoader(true));
    deleteViewApi(formdata)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        setShowDelete(false);
        setShowReason(false);
        toast.success(res?.message);
        getProduct();
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log(err, "err");
      });
  };

  const getProduct = () => {
    const formdata = new FormData();
    // if (type) {
    if (type?.id) {
      formdata.append("project_id", type?.id);
    } else {
      formdata.append("project_id", location?.state?.id);
    }
    dispatch(saveLoader(true));
    projectproductViewApi(formdata)
      .unwrap()
      .then((res) => {
        // console.log(res);
        dispatch(saveLoader(false));
        setList(res?.data);
        setclosing_date(res?.data?.client_files);
        if (res?.data?.projectDetail?.length > 0) {
          setPendingItem(res?.data?.projectDetail?.[0]);

          if (res?.data?.projectDetail?.[0]?.artworks?.length > 0) {
            setArtWork(res?.data?.projectDetail?.[0]?.artworks?.[0]);
            setArtId(res?.data?.projectDetail?.[0]?.artworks?.[0]?.id);
          }
        }
        const allItemsHaveArtworkStatus3 = res?.data?.projectDetail.every(
          (item) => item?.artwork_status === 3
        );

        if (allItemsHaveArtworkStatus3) {
          setOrders(true);
        } else {
          setOrders(false);
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log(err);
      });
  };

  const createOrder = () => {
    const formdata = new FormData();
    // if (type) {
    if (type?.id) {
      formdata.append("project_id", type?.id);
    } else {
      formdata.append("project_id", location?.state?.id);
    }
    dispatch(saveLoader(true));
    approveViewApi(formdata)
      .unwrap()
      .then((res) => {
        // navigate("/my-order");
        navigate("/my-products", { state: { tab: 2 } });
        toast.success(res?.message);
        dispatch(saveLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(saveLoader(false));
      });
  };

  useEffect(() => {
    getProduct();
  }, []);

  const showPopupHander = () => {
    setShowModal(!showModal);
  };

  const showReasonPopupHander = () => {
    setShowReason(!showReason);
  };

  const deleteToggle = (type) => {
    setShowDelete(!showDelete);
    if (type == 1) {
      // setShowReason(true);
      getDelete("Delete Product Static Reason");
    }
  };

  const onHandlePending = (item) => {
    setPendingItem(item);
  };

  return (
    <div className="d-flex my-peoduct-det">
      <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-85 px-2 px-lg-2 left-mock-req">
        <div className="d-flex ac-jb w-100">
          {/* <div className="bread-crumb d-flex ac-jc bg-lt-blue"> */}
          <div className="d-flex ac-jc">
            {/* <Link to="/my-products" className="primary1">
              {"Product"}
            </Link>
            <ArrowForwardIosIcon className="primary" />
            <Link to="" className="primary">
              {`Product ${
                ordertyp == "order"
                  ? type?.project_code
                  : location?.state?.project_code
                  ? location?.state?.project_code
                  : location?.state?.project_request_id
              }`}
            </Link> */}
          </div>
          {type !== null && (
            <div className="d-flex flex-column">
              {list?.status == 4 && (
                <button
                  className={`mb-3 border-0 accepted_btn_prime text-white mt-3 mt-sm-0 py-2 rounded rounded-3 px-3 py-lg-2 px-lg-4 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-18 ${
                    !orders ? "opacity-50" : "opacity-100"
                  }`}
                  // onClick={() => navigate("/my-order")}
                  disabled={orders ? false : true}
                  onClick={() => createOrder()}
                >
                  Create Order
                </button>
              )}
              {/* {(pendingitem?.artwork_status == 2 ||
                pendingitem?.artwork_status == 1) && (
                <button
                  className="mb-2 border-0  bg-success text-white mt-3 mt-sm-0 py-2 rounded rounded-3 px-3 py-lg-2 px-lg-4 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-18"
                  onClick={() => getApprove(3)}
                >
                  Approve Design
                </button>
              )} */}
            </div>
          )}
        </div>
        <ProductHeader
          title={`Product ${
            type == null
              ? list?.project_request_id
                ? list?.project_request_id
                : ""
              : list?.project_code
              ? list?.project_code
              : ""
          }`}
          list={list}
          type={type}
          pendingitem={pendingitem}
        />
        {/* <ProductDetail /> */}
        <div className="d-flex flex-column flex-sm-row align-items-start p-2 my-2 product-list-detail mt-2 mt-lg-5">
          <div className="col-md-4">
            {/* PRODUCT TILE START */}
            {list?.projectDetail?.map((item, ind) => {
              const first = item?.projectPresentationPrice;
              const firstTotal =
                first && first.length > 0 ? first[0].total : null;
              const lastTotal =
                first && first.length > 1
                  ? first[first.length - 1].total
                  : null;

              let newQty = item?.qty;
              let value = 25;
              if (newQty >= 1 && newQty <= 25) {
                value = 25;
              } else if (newQty >= 26 && newQty <= 50) {
                value = 50;
              } else if (newQty >= 51 && newQty <= 100) {
                value = 100;
              } else if (newQty >= 101 && newQty <= 150) {
                value = 150;
              } else if (newQty >= 151 && newQty <= 250) {
                value = 250;
              } else if (newQty >= 251 && newQty <= 500) {
                value = 500;
              } else if (newQty >= 501 && newQty <= 1000) {
                value = 1000;
              } else {
                value = 1000;
              }

              return (
                <div
                  className={`d-flex ${
                    pendingitem == item
                      ? "box-shadow-product_view"
                      : "box-shadow-product"
                  } my-3`}
                  onClick={() => {
                    onHandlePending(item);
                    if (item?.artworks?.length > 0) {
                      setArtWork(item?.artworks?.[0]);
                    }
                  }}
                  role="button"
                  key={ind}
                >
                  <div className="img-box-tee me-2">
                    <img
                      src={
                        item?.product?.defaultImage
                          ? item?.product?.defaultImage
                          : noLogo
                      }
                      className="w-100"
                    />
                  </div>
                  <div className=" ms-2">
                    <h3 className="mb-2 f4 fs-xs-15 fs-sm-17 fs-lg-18">
                      {item?.product?.name ? item?.product?.name : ""}
                    </h3>
                    <p className="parag mb-0">
                      {item?.projectPresentationPrice?.length <= 0
                        ? `₹${item?.product?.[`for_${+value}`]}/per item`
                        : `₹${firstTotal !== null ? firstTotal : ""}/per item`}
                      {lastTotal !== null ? ` - ₹${lastTotal}/per item` : ""}
                    </p>
                    <button
                      className={`border-0 text-center mb-2 mt-2 f2 fs-xs-12 fs-sm-13 fs-xl-14 fs-xxl-15  text-white px-2 py-1 d-initial rounded ${
                        item?.artwork_status == 1
                          ? "bg-processs"
                          : item?.artwork_status == 2
                          ? "bg-ready_pro"
                          : item?.artwork_status == 3
                          ? "bg-approved_pro"
                          : item?.artwork_status == 4
                          ? "bg-rejeted"
                          : "bg-ready_pro"
                      }`}
                    >
                      {item?.artwork_status == 1
                        ? "Design In Process"
                        : item?.artwork_status == 2
                        ? "Design Completed"
                        : item?.artwork_status == 3
                        ? "Approved By Me"
                        : item?.artwork_status == 4
                        ? "Rejected By Me"
                        : ""}
                    </button>
                  </div>
                </div>
              );
            })}
            {/* <div className="d-flex box-shadow-product my-3">
              <div className="img-box-tee me-2">
                <img
                  src="https://thedotmode.com/cdn/shop/files/mockup-of-a-t-shirt-hanging-against-a-solid-background-26878.png?v=1686928473&width=823"
                  className="w-100"
                />
              </div>
              <div className=" ms-2">
                <h3 className="mb-2 f4 fs-xs-15 fs-sm-17 fs-lg-18">
                  Next Level Crew Tee 2
                </h3>
                <p className="parag mb-0">₹18.59/per item</p>
                <p className="border-0 text-center mb-2 f2 fs-xs-12 fs-sm-13 fs-xl-14 fs-xxl-15 bg-pending text-white px-2 py-1 d-initial rounded">
                  Pending Review
                </p>
              </div>
            </div>
            <div className="d-flex box-shadow-product my-3">
              <div className="img-box-tee me-2">
                <img
                  src="https://thedotmode.com/cdn/shop/files/mockup-of-a-t-shirt-hanging-against-a-solid-background-26878.png?v=1686928473&width=823"
                  className="w-100"
                />
              </div>
              <div className=" ms-2">
                <h3 className="mb-2 f4 fs-xs-15 fs-sm-17 fs-lg-18">
                  Next Level Crew Tee 3
                </h3>
                <p className="parag mb-0">₹18.59/per item</p>
                <p className="border-0 text-center mb-2 f2 fs-xs-12 fs-sm-13 fs-xl-14 fs-xxl-15 bg-pending text-white px-2 py-1 d-initial rounded">
                  Pending Review
                </p>
              </div>
            </div> */}
            {/* PRODUCT TILE END */}
          </div>
          <div className="d-flex flex-column flex-md-row product-zoom-color me-0 ms-auto d-flex col-md-8">
            <ProductZoom
              pendingitem={pendingitem}
              data={list}
              type={type}
              artwrk={artwrk}
              setArtWork={setArtWork}
              setArtId={setArtId}
            />
            <ProductColor
              showPopupHander={showPopupHander}
              setShowDelete={setShowDelete}
              pendingitem={pendingitem}
              data={list}
              type={type}
              setArtWork={setArtWork}
              artwrk={artwrk}
              artId={artId}
              setArtId={setArtId}
              getApprove={getApprove}
            />
          </div>
        </div>
      </div>
      {showModal && (
        <ProductPopupComponentDecline
          showModal={showModal}
          showPopupHander={showPopupHander}
          type={type}
          list={list}
          closing_date={closing_date}
          setclosing_date={setclosing_date}
          pendingitem={pendingitem}
          getProduct={getProduct}
        />
      )}
      {showDelete && (
        <DeletePopup deleteToggle={deleteToggle} getDelete={getDelete} />
      )}
      {showReason && (
        <ReasonPopup
          showReasonPopupHander={showReasonPopupHander}
          getDelete={getDelete}
        />
      )}
    </div>
  );
};

export default MyProjectProduct;

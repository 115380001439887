// @ts-nocheck
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  build_Pack,
  bulkPack,
  presetPack,
  ecommerce,
  design,
  preset,
  bulk,
  blank,
  abt_Pack,
  sus_Pack,
  blog_Pack,
  faq_Pack,
  giv_back,
  logist,
  presetImg,
  buildImg,
  bulkImg,
} from "../assets/img";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const MenuItem = ({
  showMenu,
  showdropList,
  setShowdropList,
  setSignShowdropList,
  setShowMenu,
  companyDropdown,
  setCompanyDropdown,
  plotformMenu,
  setPlotformMenu,
}) => {
  const Token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  // console.log(splitLocation);
  const onClickPageNavigate = (e) => {
    if (e == "build") {
      navigate("/products/build-pack");
    } else if (e == "preset") {
      navigate("/products/preset-pack");
    } else if (e == "bulk") {
      navigate("/products/bulk-pack");
    } else if (e == "e-commerce") {
      if (Token) {
        navigate("/products/e-commerce");
      } else {
        navigate("/login");
      }
    }
    setShowdropList(false);
    setShowMenu(false);
    setCompanyDropdown(false);
    setPlotformMenu(false);
  };
  const onClickPlotformPageNavigate = (e) => {
    if (e == "membership") {
      navigate("platform/membership");
    } else if (e == "redeem") {
      navigate("platform/redeem");
    } else if (e == "services") {
      navigate("platform/services");
    }
    // console.log("ssss");
    setShowdropList(false);
    setCompanyDropdown(false);
    setShowMenu(false);
    setPlotformMenu(false);
  };
  const onClickCompanyPageNavigate = (e) => {
    if (e == "about-us") {
      navigate("company/about-us");
    } else if (e == "blog") {
      navigate("company/blog");
    } else if (e == "faq") {
      navigate("company/faq");
    } else if (e == "sustainability") {
      navigate("company/sustainability");
    } else if (e == "give-back") {
      navigate("/company/give-back");
    }
    setCompanyDropdown(false);
    setShowMenu(false);
    setPlotformMenu(false);
  };
  return (
    <div
      className="menu-item d-md-flex"
      style={{
        right: showMenu ? "0px" : "-100%",
        visibility: showMenu ? "visible" : "hindden",
      }}
    >
      <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <p
          className={`${showMenu ? "d-flex" : "d-none"} closebtn`}
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        >
          <CloseIcon />
        </p>
        <button
          onClick={() => {
            setShowdropList(!showdropList);
            setSignShowdropList(false);
            setCompanyDropdown(false);
            setPlotformMenu(false);
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-17 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          <span className="me-1">Products</span>
          <KeyboardArrowDownIcon className="d-lg-none" />
        </button>
        {splitLocation[1] === "products" && (
          <div className="active d-none d-md-flex" />
        )}
        <div className={`${showdropList ? "d-block" : "d-none"} drop-list`}>
          <button
            onClick={() => onClickPageNavigate("build")}
            className="border-0 bg-transparent d-flex align-items-center py-2 w-100 "
          >
            <div className="left-imgs">
              <img
                // src={design}
                src={buildImg}
                alt=""
              />
            </div>
            <div className="text-start ms-3 dorp-rit-cont">
              <h5>Design your Kit</h5>
              <p>Build your own kit </p>
            </div>
          </button>
          <button
            onClick={() => onClickPageNavigate("preset")}
            className="border-0 bg-transparent d-flex align-items-center py-2 w-100"
          >
            <div className="left-imgs">
              <img
                // src={preset}
                src={presetImg}
                alt=""
              />
            </div>
            <div className="text-start ms-3 dorp-rit-cont">
              <h5>Preset Kits</h5>
              <p>Choose from our preset kits</p>
            </div>
          </button>
          <button
            onClick={() => onClickPageNavigate("bulk")}
            className="border-0 bg-transparent d-flex align-items-center py-2 w-100"
          >
            <div className="left-imgs">
              <img
                //  src={bulk}
                src={bulkImg}
                alt=""
              />
            </div>
            <div className="text-start ms-3 dorp-rit-cont">
              <h5>Bulk Merchandise</h5>
              <p>Order merchandise in bulk</p>
            </div>
          </button>
          {/* <button
            onClick={() => onClickPageNavigate("e-commerce")}
            className="border-0 bg-transparent d-flex align-items-center py-2 w-100"
          >
            <div className="left-imgs">
              <img src={blank} alt="" />
            </div>
            <div className="text-start ms-3 dorp-rit-cont">
              <h5>Buy a Blank</h5>
              <p>Shop unbranded merch</p>
            </div>
          </button> */}
        </div>
      </div>
      <button
        onClick={() => onClickPlotformPageNavigate("services")}
        className="menu-list text-start text-lg-center menu-head-btn fs-17 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
      >
        <span className="me-1">Our Process</span>
      </button>
      {/* <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setPlotformMenu(!plotformMenu);
            setSignShowdropList(false);
            setShowdropList(false);
            setCompanyDropdown(false);
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start">
          <span className="me-1">Platform</span>
          <KeyboardArrowDownIcon className="d-lg-none" />
        </button>
        {splitLocation[1] === "platform" && (
          <div className="active d-none d-md-flex" />
        )}
        <div className={`${plotformMenu ? "d-block" : "d-none"} drop-list`}>
          <button
            onClick={() => onClickPlotformPageNavigate("membership")}
            className="border-0 bg-transparent d-flex align-items-center py-2 w-100 ">
            <div className="left-imgs">
              <img src={build_Pack} alt="" />
            </div>
            <div className="text-start ms-3 dorp-rit-cont">
              <h5>Membership</h5>
              <p>Create your own custom pack</p>
            </div>
          </button>
          <button
            onClick={() => onClickPlotformPageNavigate("redeem")}
            className="border-0 bg-transparent d-flex align-items-center py-2 w-100">
            <div className="left-imgs">
              <img src={presetPack} alt="" />
            </div>
            <div className="text-start ms-3 dorp-rit-cont">
              <h5>Redeem</h5>
              <p>Select one of our preset packes</p>
            </div>
          </button>
          <button
            onClick={() => onClickPlotformPageNavigate("services")}
            className="border-0 bg-transparent d-flex align-items-center py-2 w-100">
            <div className="left-imgs">
              <img src={presetPack} alt="" />
            </div>
            <div className="text-start ms-3 dorp-rit-cont">
              <h5>Services</h5>
              <p>Select one of our preset packes</p>
            </div>
          </button>
        </div>
      </div> */}
      <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setCompanyDropdown(!companyDropdown);
            setSignShowdropList(false);
            setShowdropList(false);
            setPlotformMenu(false);
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-17 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          <span className="me-1">The HQ</span>
          <KeyboardArrowDownIcon className="d-lg-none" />
        </button>
        {splitLocation[1] === "company" && (
          <div className="active d-none d-md-flex" />
        )}
        <div className={`${companyDropdown ? "d-block" : "d-none"} drop-list`}>
          <button
            onClick={() => onClickCompanyPageNavigate("about-us")}
            className="border-0 bg-transparent d-flex align-items-center py-2 w-100 "
          >
            <div className="left-imgs">
              <img src={abt_Pack} alt="" />
            </div>
            <div className="text-start ms-3 dorp-rit-cont">
              <h5>About Us</h5>
              <p>Welcome to our world.</p>
            </div>
          </button>
          <button
            onClick={() => onClickCompanyPageNavigate("sustainability")}
            className="border-0 bg-transparent d-flex align-items-center py-2 w-100 "
          >
            <div className="left-imgs">
              <img src={sus_Pack} alt="" />
            </div>
            <div className="text-start ms-3 dorp-rit-cont">
              <h5>Sustainabilty</h5>
              <p>Impact matters. Explore ours.</p>
            </div>
          </button>
          {/* <button
            onClick={() => onClickCompanyPageNavigate("give-back")}
            className="border-0 bg-transparent d-flex align-items-center py-2 w-100 "
          >
            <div className="left-imgs">
              <img src={giv_back} alt="" />
            </div>
            <div className="text-start ms-3 dorp-rit-cont">
              <h5>Givebacks</h5>
              <p>Let’s bridge the gap together.</p>
            </div>
          </button> */}
          <button
            onClick={() => onClickCompanyPageNavigate("blog")}
            className="border-0 bg-transparent d-flex align-items-center py-2 w-100"
          >
            <div className="left-imgs">
              <img src={blog_Pack} alt="" />
            </div>
            <div className="text-start ms-3 dorp-rit-cont">
              <h5>Blog</h5>
              <p>Explore, learn & grow.</p>
            </div>
          </button>
          <button
            onClick={() => onClickCompanyPageNavigate("faq")}
            className="border-0 bg-transparent d-flex align-items-center py-2 w-100"
          >
            <div className="left-imgs">
              <img src={faq_Pack} alt="" />
            </div>
            <div className="text-start ms-3 dorp-rit-cont">
              <h5>FAQ</h5>
              <p>Got any questions? Answers here!</p>
            </div>
          </button>
        </div>
      </div>
      {/* <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setCompanyDropdown(false);
            setSignShowdropList(false);
            setShowdropList(false);
            setPlotformMenu(false);
            setShowMenu(false);
            navigate("/company/faq");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-17 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start "
        >
          <span className="mb-1">FAQ</span>
        </button>
        {splitLocation[1] === "pricing" && (
          <div className="active d-none d-md-flex" />
        )}
      </div> */}
      {/* <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setCompanyDropdown(false);
            setSignShowdropList(false);
            setShowdropList(false);
            setPlotformMenu(false);
            setShowMenu(false);
            navigate("/pricing");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-17 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start "
        >
          <span className="mb-1">Tools</span>
        </button>
        {splitLocation[1] === "pricing" && (
          <div className="active d-none d-md-flex" />
        )}
      </div> */}
    </div>
  );
};

export default MenuItem;

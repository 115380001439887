// @ts-nocheck
import React, { useEffect, useState } from "react";
import MockReqCart from "../component/Product/MockReqCart";
import TellProject from "../component/Product/mock/TellProject";
import ProductPopupComponent from "../component/Product/Build/ProductPopupComponent";
import { Link, useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ContactDetails from "../component/Product/mock/ContactDetails";
import {
  useAddToCartMutation,
  useCartListMutation,
  useDeleteCartMutation,
  usePackUpdateApiMutation,
  useProductListMutation,
  useProductPriceListMutation,
  useProductReqMutation,
  useProductViewMutation,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";

const MockupRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tick, setTick] = useState([]);
  const [conatctDetail, setConatctDetail] = useState(false);
  const cartProduct = JSON.parse(localStorage.getItem("cart"));
  const userDetails = JSON.parse(localStorage.getItem("user_details"));
  const Token = localStorage.getItem("token");
  const [dataBase, setDataBase] = useState(null);
  const [qtyBtn, setQtyBtn] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [viewId, setViewId] = useState("");
  const [viewList, setViewList] = useState([]);
  const [imagesid, setImagesId] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [packsPrice, setPacksPrice] = useState("");
  const [count, setCount] = useState(1);
  const [colorcount, setColorCount] = useState(1);
  const [cortList, setCartList] = useState([]);
  const [priceList, setPriceList] = useState();
  const [bigImg, setBigImg] = useState(0);
  const [style, setStyle] = useState("");
  const [size, setSize] = useState("");
  const [material, setMaterial] = useState("");
  const [selectedColorId, setSelectedColorId] = useState("");
  const [selectColorId, setSelectColorId] = useState("");
  const [skuCodeList, setCodeList] = useState([]);
  const [skuCode, setCode] = useState("");
  const [pack, setPack] = useState(25);
  const [totalPrice, setTotalPrice] = useState("");
  const [totalPerPrice, setTotalPerPrice] = useState("");
  const [ListPrice, setListPrice] = useState({});
  const [cartData, setCartData] = useState({});
  const [userCartID, setUserCartID] = useState("");
  const [checker, setChecker] = useState(false);
  const [btn, setBtn] = useState(false);
  const [viewData, setViewData] = useState({});
  const [noOfPack, setNoOfPack] = useState("");
  const [noOfPackErr, setNoOfPackErr] = useState(false);
  const [showoption, setShowOption] = useState(true);

  const [date, setDate] = useState("");
  const [comments, setComments] = useState("");
  const [primaryLogo, setPrimaryLogo] = useState("");
  const [secondryLogo, setSecondryLogo] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [organization, setOrganization] = useState("");
  const [manyPacks, setManyPacks] = useState("");
  const [budgetPack, setBugetPack] = useState("");

  const [dateErr, setDateErr] = useState(false);
  const [commentsErr, setCommentsErr] = useState(false);
  const [primaryLogoErr, setPrimaryLogoErr] = useState(false);
  const [secondryLogoErr, setSecondryLogoErr] = useState(false);

  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [numberErr, setNumberErr] = useState(false);
  const [organizationErr, setOrganizationErr] = useState(false);
  const [manyPacksErr, setManyPacksErr] = useState(false);
  const [budgetPackErr, setBugetPackErr] = useState(false);
  const [cartBtn, setCartBtn] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);

  const [no_pack, setNo_of_pack] = useState(25);

  const showContactHandler = () => {
    setConatctDetail(!conatctDetail);
  };

  const showPopupHander = () => {
    setShowPopup(!showPopup);
  };

  const addProductHandler = (item, ind, product, inx) => {
    if (Array.isArray(cartProduct)) {
      console.log(222);
      let temp = [...cartProduct];
      let index = temp.indexOf(product);
      if (temp.includes(product)) {
        temp.splice(index, 1);
      } else {
        temp.push(product);
      }
      console.log("temp");
      localStorage.setItem("cart", JSON.stringify(temp));
    } else {
      let temp = [];
      temp.push(product);
      localStorage.setItem("cart", JSON.stringify(temp));
      console.log(111);
    }

    // setTick(temp);
  };

  // RTK QUERY
  const [deleteApi] = useDeleteCartMutation();
  const [productViewApi] = useProductViewMutation();
  const [cartListApi] = useCartListMutation();
  const [productPriceApi] = useProductPriceListMutation();
  const [addToCartApi] = useAddToCartMutation();
  const [productReqApi] = useProductReqMutation();
  const [packUpdateApi] = usePackUpdateApiMutation();
  const [ProductListApi] = useProductListMutation();

  // TICK PRODUCT
  const onClickProduct = (product) => {
    const formData = new FormData();
    setViewId(product);
    formData?.append("product_ids", product);
    dispatch(saveLoader(true));
    productViewApi({ payload: formData, id: product })
      .unwrap()
      .then((res) => {
        let obj = { ...cartData };
        setListPrice(res?.product);
        setCodeList(res?.product?.product_varient_skus);
        setSelectedColorId(res?.product?.colors[0]?.name);
        setSelectColorId(res?.product?.colors[0]?.id);
        setImagesId(res?.product);
        setPacksPrice(res?.product?.for_25);
        setStyle(res?.product?.styles?.[0]?.value);
        setMaterial(res?.product?.materials?.[0]?.value);
        setSize(res?.product?.sizes?.[0]?.value);

        let object = obj[res?.product?.id];
        let final = {
          ...obj,
          [res?.product?.id]: {
            ...object,
            for_price: +res?.product?.for_25,
            pack: 25,
            count_value: 1,
          },
        };

        // setCartData(final);
        const temp = [];
        temp?.push(res?.product);

        const list = [];
        const image = [];
        temp?.map((item, ind) => {
          item?.colorImages?.map((color) => {
            list.push({ img: color?.image_url });
          });
          item?.colors?.map((hex) => {
            list?.push({ ...hex, hex_code: hex?.hex_code });
          });

          image?.push({
            ...item,
            images: list,
          });
        });
        // console.log(image);
        // setStyle(ima)
        setViewList(image);

        const formData = new FormData();
        formData.append("type", 1);
        formData.append("no_of_packs", 25);
        formData.append("product_id", res?.product?.id);
        formData.append(
          "product_sku_id",
          res?.product?.product_varient_skus?.[0]?.id
        );
        formData.append("qty", 1);
        formData.append("no_of_colors", 1);
        formData.append("price", res?.product?.for_25);

        // console.log("productId", productId);

        addToCartApi(formData)
          .unwrap()
          .then((resp) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            const temp = [];
            temp.push(viewId);
            setTick(temp);
            getCartApi();
            // console.log(res);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch(saveLoader(false));
        setCartBtn(false);
      });
  };

  // CART LIST
  const getCartApi = () => {
    const formData = new FormData();
    formData.append("type", 1);
    dispatch(saveLoader(true));
    cartListApi(formData)
      .unwrap()
      .then((res) => {
        if (!res?.cart_detail?.id) {
          navigate("/products/build-pack");
        } else {
          const data = res?.cart_detail?.cartDetail;
          const temp = [];
          let tempArr = [];
          data?.map((item) => {
            temp?.push(item?.product?.id);
            setTick(temp);
          });

          if (res?.cart_detail?.id) {
            let qtys = res?.cart_detail?.no_of_packs;
            let total_price_cart;
            setNo_of_pack(res?.cart_detail?.no_of_packs);
            res?.cart_detail?.cartDetail?.map((item, ind) => {
              let qty_val = item?.product?.[`for_${qtys}`];
              let sum = +item?.qty * qtys;
              let obj = {
                for_price: +qty_val,
                pack: res?.cart_detail?.no_of_packs,
                count: +item?.qty,
                cart_id: item?.id,
                no_of_colors: item?.no_of_colors,
              };

              if (qty_val) {
                obj.for_price = +qty_val;
              } else {
                obj.for_price = +qtys;
              }

              if (+sum >= 1 && +sum <= 25) {
                obj.packs_price = +item?.product?.for_25;
                total_price_cart = +item?.product?.for_25;
              } else if (+sum > 25 && +sum <= 50) {
                obj.packs_price = +item?.product?.for_50;
                total_price_cart = +item?.product?.for_50;
              } else if (+sum > 50 && +sum <= 100) {
                obj.packs_price = +item?.product?.for_100;
                total_price_cart = +item?.product?.for_100;
              } else if (+sum > 100 && +sum <= 150) {
                obj.packs_price = +item?.product?.for_150;
                total_price_cart = +item?.product?.for_150;
              } else if (+sum > 150 && +sum <= 250) {
                obj.packs_price = +item?.product?.for_250;
                total_price_cart = +item?.product?.for_250;
              } else if (+sum > 250 && +sum <= 500) {
                obj.packs_price = +item?.product?.for_500;
                total_price_cart = +item?.product?.for_500;
              } else if (+sum > 500 && +sum <= 1000) {
                obj.packs_price = +item?.product?.for_1000;
                total_price_cart = +item?.product?.for_1000;
              } else if (+sum > 1000) {
                obj.packs_price = +item?.product?.for_1000;
                total_price_cart = +item?.product?.for_1000;
              }

              let cart_detail = {
                ...item,
                total_price: total_price_cart,
              };

              if (item?.product_sku?.id) {
                let finder = item?.product?.colorImages?.find(
                  (i) => i?.color_id == item?.product_sku?.color_id
                );

                if (finder?.id) {
                  cart_detail.selected_image_url = finder?.image_url;
                } else {
                  cart_detail.selected_image_url = item?.product?.defaultImage;
                }
              } else {
                cart_detail.selected_image_url = item?.product?.defaultImage;
              }

              tempArr.push(cart_detail);

              setCartData((val) => ({
                ...val,
                [item?.product?.id]: obj,
              }));
            });

            let resobj = {
              ...res?.cart_detail,
              cartDetail: tempArr,
            };
            // console.log(resobj, "resobj");
            setViewData(resobj);
            setPack(res?.cart_detail?.no_of_packs);
            setTotalPrice(res?.cart_detail?.total_amount);
            setTotalPerPrice(res?.cart_detail?.price_per_pack);
            setCartList(tempArr);
            setPriceList(res?.pack_prices);
            setManyPacks(res?.cart_detail?.no_of_packs);
            setBugetPack(res?.cart_detail?.price_per_pack);
          } else {
            setCartList([]);
            setPriceList({});
          }
          dispatch(saveLoader(false));
          setQtyBtn(false);
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setQtyBtn(false);
        console.log(err);
      });
  };

  // PRODUCT VIEW
  const getProductView = (id, cartID, item) => {
    const formData = new FormData();
    setViewId(id);
    formData?.append("product_ids", id);
    dispatch(saveLoader(true));
    productViewApi({ payload: formData, id: id })
      .unwrap()
      .then((res) => {
        let obj = { ...cartData };

        // setUserCartID(cartID);
        setListPrice(res?.product);
        setCodeList(res?.product?.product_varient_skus);
        setImagesId(res?.product);
        setPacksPrice(res?.product?.for_25);
        setStyle(res?.product?.styles?.[0]?.value);
        setMaterial(res?.product?.materials?.[0]?.value);
        setSize(res?.product?.sizes?.[0]?.value);

        let object = obj[res?.product?.id];
        let obj_val = {
          ...object,
          for_price: +res?.product?.for_25,
          count_value: 1,
        };
        if (obj[res?.product?.id]?.pack) {
          obj_val.pack = obj[res?.product?.id]?.pack;
          obj_val.no_of_colors = obj[res?.product?.id]?.no_of_colors;
          obj_val.sku_id = obj[res?.product?.id]?.sku_id;
        } else {
          obj_val.pack = 25;
          obj_val.no_of_colors = 1;
          obj_val.sku_id = res?.product?.product_varient_skus?.[0]?.id;
        }

        if (obj[res?.product?.id]?.packs_price) {
          obj_val.packs_price = obj[res?.product?.id]?.packs_price;
        }

        let final = {
          ...obj,
          [res?.product?.id]: obj_val,
        };

        setCartData(final);
        const temp = [];
        temp?.push(res?.product);
        const image = [];
        temp?.map((item, ind) => {
          item?.colorImages?.map((color) => {
            let color_id_finder = item?.colors?.find(
              (i) => i?.id == color?.color_id
            );

            image.push({
              ...color,
              color: color_id_finder,
            });
          });
        });
        const uniqueColors = [];
        temp?.map((item, ind) => {
          item?.product_varient_skus?.map((color) => {
            let finderImage = image?.find(
              (i) => i?.color_id == color?.color_id
            );

            uniqueColors.push(finderImage);
          });
        });
        const uniqueId = new Map(uniqueColors.map((c) => [c.color_id, c]));
        const DefultImages = [...uniqueId.values()];
        let colorFinder = DefultImages?.find(
          (i) => i?.color_id == item?.product_sku?.color_id
        );
        let findIndex = DefultImages?.findIndex(
          (i) => i?.color_id == item?.product_sku?.color_id
        );

        if (item?.product_sku?.id) {
          setSelectedColorId(colorFinder?.color?.name);
          setSelectColorId(colorFinder?.color?.id);
          setBigImg(findIndex);
        } else {
          setSelectedColorId(DefultImages?.[0]?.color?.name);
          setSelectColorId(DefultImages?.[0]?.color?.id);
          setBigImg(0);
        }

        // setStyle(ima)
        // console.log("temps", temps);
        setViewList([
          {
            ...res?.product,
            images: DefultImages,
          },
        ]);
        showPopupHander();
        dispatch(saveLoader(false));
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        console.log(err);
      });
  };

  // DELETE CART
  const deleteCart = (id, pro_id) => {
    if (!Token) {
      deleteLocalCart(pro_id);
    } else {
      const formData = new FormData();
      formData.append("user_cart_detail_id", id);
      // console.log("pro_id", pro_id);
      dispatch(saveLoader(true));
      setDeleteBtn(true);
      deleteApi(formData)
        .unwrap()
        .then((res) => {
          // console.log(res?.navigations);
          // setCategoryList(res?.navigations);

          dispatch(saveLoader(false));
          setDeleteBtn(false);
          let obj = { ...cartData };
          delete obj[pro_id];
          setCartData(obj);
          getCartApi();
          let temp = [...tick];
          let inx = temp.indexOf(pro_id);
          temp.splice(inx, 1);
          setTick(temp);
          setShowPopup(false);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setDeleteBtn(false);
          console.log("err", err);
        });
    }
  };

  const product = () => {
    const formData = new FormData();
    ProductListApi(formData)
      .unwrap()
      .then((res) => {
        // console.log(res?.navigations);
        setCategoryList(res?.navigations);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // ADD TO CART
  const varients = (id, qty, packsPrice) => {
    let temp = "";
    if (selectedColorId) {
      temp = `/${selectedColorId}`;
    }
    if (size) {
      temp = temp + `/${size}`;
    }

    if (material) {
      temp = temp + `/${material}`;
    }

    if (style) {
      temp = temp + `/${style}`;
    }

    // console.log(temp, "temp");
    let codeFinder = skuCodeList?.find((i) => i?.variant_name?.includes(temp));
    // console.log("codeFinder", codeFinder);
    // setCode(skuCode)
    if (codeFinder?.id) {
      dispatch(saveLoader(true));
      setCartBtn(true);
      getAddToCart(codeFinder?.id, id, qty, packsPrice);
    }
  };

  // ADD CART
  const getAddToCart = (skuId, productId, qty, packsPrice) => {
    let pack_value = cartData?.[productId]?.pack;

    let packs_val = +cartData?.[productId]?.packs_price
      ? +cartData?.[productId]?.packs_price
      : +cartData?.[productId]?.for_price;

    let count_value = cartData?.[productId]?.count
      ? +cartData?.[productId]?.count
      : +cartData?.[productId]?.count_value;

    let no_of_colors = cartData?.[productId]?.no_of_colors
      ? +cartData?.[productId]?.no_of_colors
      : 1;

    let temp = [];

    temp.push({
      product_id: productId,
      product_sku_id: skuId,
      no_of_colors: no_of_colors,
      qty: qty ? qty : count_value,
      price: +packsPrice ? +packsPrice : +packs_val,
    });

    const formData = new FormData();
    formData.append("type", 1);
    formData.append("no_of_packs", no_pack);
    // formData.append("product_id", productId);
    // formData.append("product_sku_id", skuId);
    // formData.append("qty", qty ? qty : count_value);
    // formData.append("no_of_colors", colorcount);
    // formData.append("price", +packsPrice ? +packsPrice : +packs_val);

    if (temp?.length > 0) {
      temp?.map((item, ind) => {
        let qty_sum = +item?.qty * +no_pack;

        if (+qty_sum >= 1 && +qty_sum <= 25) {
          qty_sum = 25;
        } else if (+qty_sum > 25 && +qty_sum <= 50) {
          qty_sum = 50;
        } else if (+qty_sum > 50 && +qty_sum <= 100) {
          qty_sum = 100;
        } else if (+qty_sum > 100 && +qty_sum <= 150) {
          qty_sum = 150;
        } else if (+qty_sum > 150 && +qty_sum <= 250) {
          qty_sum = 250;
        } else if (+qty_sum > 250 && +qty_sum <= 500) {
          qty_sum = 500;
        } else if (+qty_sum > 500 && +qty_sum <= 1000) {
          qty_sum = 1000;
        } else if (+qty_sum > 1000) {
          qty_sum = 1000;
        }

        formData.append(`cart_details[${ind}][qty]`, item?.qty);
        formData.append(`cart_details[${ind}][product_id]`, item?.product_id);
        formData.append(
          `cart_details[${ind}][product_sku_id]`,
          item?.product_sku_id
        );

        formData.append(
          `cart_details[${ind}][no_of_colors]`,
          item?.no_of_colors
        );

        if (item?.product_id == ListPrice?.id) {
          formData.append(
            `cart_details[${ind}][price]`,
            ListPrice?.[`for_${qty_sum}`]
          );
        } else {
          formData.append(`cart_details[${ind}][price]`, item?.price);
        }
      });
    }

    // console.log("productId", productId);

    addToCartApi(formData)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        if (!qty) {
          showPopupHander();
        }
        const temp = [];
        temp.push(viewId);
        setTick(temp);
        getCartApi();
        // console.log(res);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        console.log(err);
      });
  };

  //PRODUCT PRICE LIST
  const productPrice = () => {
    const formData = new FormData();
    if (selectedIds?.length > 0) {
      selectedIds?.map((item, ind) => {
        formData.append(`product_ids[${ind}]`, item);
      });
    }
    productPriceApi(formData)
      .unwrap()
      .then((res) => {
        const price = res?.total_price;
        setPacksPrice(price?.[pack]);

        // setPriceList(price);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calculations = (qty, id, packs, type) => {
    setQtyBtn(true);
    let qty_val;
    if (type == "decrement") {
      qty_val = +qty - 1;
    } else {
      qty_val = +qty + 1;
    }

    let qty_sum = +qty_val * +cartData?.[id]?.pack;

    // if (+qty_sum <= 1000) {
    let obj = {};
    if (+qty_sum >= 1 && +qty_sum <= 25) {
      setPacksPrice(+ListPrice?.for_25);
      obj.packs_price = +ListPrice?.for_25;
    } else if (+qty_sum > 25 && +qty_sum <= 50) {
      setPacksPrice(+ListPrice?.for_50);
      obj.packs_price = +ListPrice?.for_50;
    } else if (+qty_sum > 50 && +qty_sum <= 100) {
      setPacksPrice(+ListPrice?.for_100);
      obj.packs_price = +ListPrice?.for_100;
    } else if (+qty_sum > 100 && +qty_sum <= 150) {
      setPacksPrice(+ListPrice?.for_150);
      obj.packs_price = +ListPrice?.for_150;
    } else if (+qty_sum > 150 && +qty_sum <= 250) {
      setPacksPrice(+ListPrice?.for_250);
      obj.packs_price = +ListPrice?.for_250;
    } else if (+qty_sum > 250 && +qty_sum <= 500) {
      setPacksPrice(+ListPrice?.for_500);
      obj.packs_price = +ListPrice?.for_500;
    } else if (+qty_sum > 500 && +qty_sum <= 1000) {
      setPacksPrice(+ListPrice?.for_1000);
      obj.packs_price = +ListPrice?.for_1000;
    } else if (+qty_sum > 1000) {
      setPacksPrice(+ListPrice?.for_1000);
      obj.packs_price = +ListPrice?.for_1000;
    }

    obj.pack = +packs;
    obj.count = +qty_val;
    obj.for_price = +cartData?.[id]?.for_price;
    obj.no_of_colors = +cartData?.[id]?.no_of_colors;

    let final = {
      ...cartData,
      [id]: obj,
    };

    setCartData(final);
    setNo_of_pack(+packs);

    if (+cartData?.[id]?.cart_id) {
      if (!Token) {
        updateLocalCart(id, +qty, "", "", "", type);
      } else {
        obj.cart_id = +cartData?.[id]?.cart_id;
        varients(
          id,
          qty_val,
          obj?.packs_price ? obj?.packs_price : obj.for_price
        );
        setQtyBtn(false);
      }
    } else {
      setQtyBtn(false);
    }
    // }
  };

  useEffect(() => {
    if (userDetails?.email) {
      setEmail(userDetails?.email);
    }
    if (userDetails?.first_name) {
      setFirstName(userDetails?.first_name);
    }
    if (userDetails?.last_name) {
      setLastName(userDetails?.last_name);
    }
    if (userDetails?.organisation) {
      setOrganization(userDetails?.organisation);
    }
    if (userDetails?.mobile) {
      setNumber("91" + userDetails?.mobile);
    }
  }, []);

  const customQty = (no_of_packs) => {
    if (!Token) {
      customQtyLocal(no_of_packs);
    } else {
      const no_of_pack = no_of_packs ? no_of_packs : noOfPack;
      if (no_of_pack?.length == 0) {
        setNoOfPackErr(true);
      } else {
        let formdata = new FormData();
        formdata.append("no_of_packs", no_of_packs ? no_of_packs : noOfPack);
        setNo_of_pack(no_of_packs ? no_of_packs : noOfPack);
        packUpdateApi(formdata)
          .unwrap()
          .then((res) => {
            setNoOfPack("");
            setNoOfPackErr(false);
            getCartApi();
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  };

  const handleContinue = () => {
    if (
      // date?.length == 0 ||
      // comments?.length == 0 ||
      // primaryLogo?.length == 0 ||
      // secondryLogo?.length == 0 ||
      firstName?.length == 0 ||
      lastName?.length == 0 ||
      email?.length == 0 ||
      number?.length < 12 ||
      organization?.length == 0 ||
      manyPacks?.length == 0 ||
      budgetPack?.length == 0
    ) {
      // setDateErr(true);
      // setCommentsErr(true);
      // setPrimaryLogoErr(true);
      // setSecondryLogoErr(true);
      setFirstNameErr(true);
      setLastNameErr(true);
      setEmailErr(true);
      setNumberErr(true);
      setOrganizationErr(true);
      setManyPacksErr(true);
      setBugetPackErr(true);
    } else {
      const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
      let count = 1;

      if (validEmail.test(email) == false) {
        count = count + 1;
      }

      if (count == 1) {
        setConatctDetail(true);
      }
    }
  };

  const submitHandle = () => {
    if (
      date?.length == 0
      // ||
      // comments?.length == 0
      // ||
      // primaryLogo?.length == 0
      // ||
      // secondryLogo?.length == 0
    ) {
      setDateErr(true);
      // setCommentsErr(true);
      // setPrimaryLogoErr(true);
      // setSecondryLogoErr(true);
    } else {
      let formdata = new FormData();

      let qty_sum = viewData?.no_of_packs;

      if (userDetails?.id) {
        formdata.append("user_id", userDetails?.id);
      }
      formdata.append("when_needed", date);
      if (comments) {
        formdata.append("notes", comments);
      }
      let images = [];
      if (primaryLogo?.name) {
        images?.push(primaryLogo);
      }
      if (secondryLogo?.name) {
        images?.push(secondryLogo);
      }

      images?.map((item, ind) => {
        formdata.append(`images[${ind}][image]`, item);
        formdata.append(`images[${ind}][type]`, ind + 1);
      });
      // if (primaryLogo) {
      //   formdata.append("primary_logo", primaryLogo);
      // }
      // if (secondryLogo) {
      //   formdata.append("secondary_logo", secondryLogo);
      // }

      formdata.append(
        "no_of_packs",
        manyPacks ? manyPacks : viewData?.no_of_packs
      );
      formdata.append("total_amount", viewData?.total_amount);
      formdata.append(
        "price_per_pack",
        budgetPack ? budgetPack : viewData?.price_per_pack
      );

      if (viewData?.PresetPack?.id) {
        formdata.append("preset_pack_id", viewData?.PresetPack?.id);
        formdata.append("request_type", 2);
      } else {
        formdata.append("request_type", 1);
      }

      formdata.append(`first_name`, firstName);
      formdata.append(`last_name`, lastName);

      if (!Token) {
        formdata.append(`email`, email);
      }

      if (number?.length == 12) {
        formdata.append("mobile", number?.slice(-10));
        formdata.append("mobile_prefix", number?.slice(0, 2));
      } else {
        formdata.append("mobile", number?.slice(3, number?.length));
        formdata.append("mobile_prefix", number?.slice(0, 3));
      }

      formdata.append(`organisation`, organization);

      viewData?.cartDetail?.map((item, ind) => {
        formdata.append(
          `project_request_details[${ind}][product_id]`,
          item?.product?.id
        );

        formdata.append(
          `project_request_details[${ind}][no_of_colors]`,
          item?.no_of_colors
        );

        formdata.append(`project_request_details[${ind}][qty]`, item?.qty);

        if (+qty_sum >= 1 && +qty_sum <= 25) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_25`]
          );
        } else if (+qty_sum > 25 && +qty_sum <= 50) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_50`]
          );
        } else if (+qty_sum > 50 && +qty_sum <= 100) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_100`]
          );
        } else if (+qty_sum > 100 && +qty_sum <= 150) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_150`]
          );
        } else if (+qty_sum > 150 && +qty_sum <= 250) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_250`]
          );
        } else if (+qty_sum > 250 && +qty_sum <= 500) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_500`]
          );
        } else if (+qty_sum > 500 && +qty_sum <= 1000) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_1000`]
          );
        } else if (+qty_sum > 1000) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_1000`]
          );
        }

        if (item?.product_sku?.id) {
          formdata.append(
            `project_request_details[${ind}][product_sku_id]`,
            item?.product_sku?.id
          );
        } else {
          formdata.append(
            `project_request_details[${ind}][product_sku_id]`,
            item?.product?.productSku?.[0]?.id
          );
        }
      });
      dispatch(saveLoader(true));
      setBtn(true);
      productReqApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);

          if (!Token) {
            let txDb = dataBase.transaction("CartTable", "readwrite");
            let storedObject = txDb.objectStore("CartTable");
            let requests = storedObject.put({}, "individual");

            requests.onsuccess = function () {
              console.log("success!");
              navigate("/shipment");
            };

            requests.onerror = function () {
              console.log(requests.error);
            };
          } else {
            navigate("/shipment");
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const varientsNew = (id, qty, packsPrice, colorID, noOfColors, type) => {
    let codeFinder = skuCodeList?.find((i) => i?.color_id == colorID);
    if (codeFinder?.id) {
      let packs_val = +cartData?.[id]?.packs_price
        ? +cartData?.[id]?.packs_price
        : +cartData?.[id]?.for_price;

      if (!Token) {
        updateLocalCart(id, qty, packs_val, codeFinder, noOfColors, type);
      } else {
        let pack_value = cartData?.[id]?.pack;

        let count_value = cartData?.[id]?.count
          ? +cartData?.[id]?.count
          : +cartData?.[id]?.count_value;

        let no_of_colors = noOfColors
          ? noOfColors
          : cartData?.[id]?.no_of_colors
          ? +cartData?.[id]?.no_of_colors
          : 1;

        let temp = [];

        temp.push({
          product_id: id,
          product_sku_id: codeFinder?.id,
          no_of_colors: no_of_colors,
          qty: qty ? qty : count_value,
          price: +packsPrice ? +packsPrice : +packs_val,
        });

        const formData = new FormData();
        formData.append("type", 1);
        formData.append("no_of_packs", pack_value);
        // formData.append("preset_pack_id", " ");

        if (temp?.length > 0) {
          temp?.map((item, ind) => {
            formData.append(`cart_details[${ind}][qty]`, item?.qty);
            formData.append(
              `cart_details[${ind}][product_id]`,
              item?.product_id
            );
            if (item?.product_sku_id) {
              formData.append(
                `cart_details[${ind}][product_sku_id]`,
                item?.product_sku_id
              );
            }

            formData.append(`cart_details[${ind}][price]`, item?.price);
            formData.append(
              `cart_details[${ind}][no_of_colors]`,
              item?.no_of_colors
            );
          });
        }

        // console.log("temp", temp);

        addToCartApi(formData)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            // if (!qty) {
            //   showPopupHander();
            // }
            // const temp = [];
            // temp.push(viewId);
            // setTick(temp);
            getCartApi();
            // console.log(res);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            console.log(err);
          });
      }
    }
  };

  // GET DATABASE
  const getDB = (skuId) => {
    var open = indexedDB.open("CartDB");

    open.onsuccess = function () {
      let dataBase = open.result;
      setDataBase(dataBase);
      console.log("success!");

      let tx = dataBase.transaction("CartTable", "readonly");
      let store = tx.objectStore("CartTable");

      let data = store.get("individual");
      data.onsuccess = (eve) => {
        // console.log("eve.target.result", eve.target.result);
        let responce = eve.target.result;

        if (!responce?.pack_prices?.[25]) {
          navigate("/products/build-pack");
        } else {
          const temp = [];
          let tempArr = [];
          responce?.cartDetail?.map((item) => {
            temp?.push(item?.product?.id);
            setTick(temp);
          });

          if (responce?.total_amount) {
            let qtys = responce?.no_of_packs;
            let total_price_cart;
            responce?.cartDetail?.map((item, ind) => {
              let qty_val = item?.product?.[`for_${qtys}`];
              let sum = +item?.qty * qtys;
              let obj = {
                for_price: +qty_val,
                pack: responce?.no_of_packs,
                count: +item?.qty,
                cart_id: 10,
                no_of_colors: item?.no_of_colors,
                product_id: item?.product?.id,
                sku_id: skuId ? skuId : item?.product_sku_id,
              };

              if (qty_val) {
                obj.for_price = +qty_val;
              } else {
                obj.for_price = +qtys;
              }

              if (+sum >= 1 && +sum <= 25) {
                obj.packs_price = +item?.product?.for_25;
                total_price_cart = +item?.product?.for_25;
              } else if (+sum > 25 && +sum <= 50) {
                obj.packs_price = +item?.product?.for_50;
                total_price_cart = +item?.product?.for_50;
              } else if (+sum > 50 && +sum <= 100) {
                obj.packs_price = +item?.product?.for_100;
                total_price_cart = +item?.product?.for_100;
              } else if (+sum > 100 && +sum <= 150) {
                obj.packs_price = +item?.product?.for_150;
                total_price_cart = +item?.product?.for_150;
              } else if (+sum > 150 && +sum <= 250) {
                obj.packs_price = +item?.product?.for_250;
                total_price_cart = +item?.product?.for_250;
              } else if (+sum > 250 && +sum <= 500) {
                obj.packs_price = +item?.product?.for_500;
                total_price_cart = +item?.product?.for_500;
              } else if (+sum > 500 && +sum <= 1000) {
                obj.packs_price = +item?.product?.for_1000;
                total_price_cart = +item?.product?.for_1000;
              } else if (+sum > 1000) {
                obj.packs_price = +item?.product?.for_1000;
                total_price_cart = +item?.product?.for_1000;
              }

              let cart_detail = {
                ...item,
                total_price: total_price_cart,
              };

              if (item?.product_sku?.id) {
                let finder = item?.product?.colorImages?.find(
                  (i) => i?.color_id == item?.product_sku?.color_id
                );

                if (finder?.id) {
                  cart_detail.selected_image_url = finder?.image_url;
                } else {
                  cart_detail.selected_image_url = item?.product?.defaultImage;
                }
              } else {
                cart_detail.selected_image_url = item?.product?.defaultImage;
              }

              tempArr.push(cart_detail);

              // console.log("obj", obj);

              setCartData((val) => ({
                ...val,
                [item?.product?.id]: obj,
              }));
            });

            let resobj = {
              ...responce,
              cartDetail: tempArr,
            };

            setViewData(resobj);
            setPack(responce?.no_of_packs);
            setTotalPrice(responce?.total_amount);
            setTotalPerPrice(responce?.price_per_pack);
            setCartList(tempArr);
            setPriceList(responce?.pack_prices);
            setManyPacks(responce?.no_of_packs);
            setBugetPack(responce?.price_per_pack);
          } else {
            setCartList([]);
            setPriceList({});
          }

          dispatch(saveLoader(false));
          setCartBtn(false);
          setQtyBtn(false);
        }

        data.onerror = function () {
          console.log(data.error);
          setQtyBtn(false);
        };
      };
    };

    open.onerror = function () {
      console.log(open.error);
    };
  };

  // UPDATE LOCAL CART
  const updateLocalCart = (id, qty, packsPrice, colors, noOfColors, type) => {
    let tx = dataBase.transaction("CartTable", "readonly");
    let store = tx.objectStore("CartTable");

    let data = store.get("individual");
    data.onsuccess = (eve) => {
      let cart_data = eve.target.result;
      let obj = { ...cart_data };
      let arr = [...obj?.cartDetail];
      let index = arr.findIndex((i) => i.product_id == id);
      let pack_no = obj.no_of_packs;
      let pack_value = obj.no_of_packs;

      // EDIT
      let txs = dataBase.transaction("CartTable", "readwrite");
      let stored = txs.objectStore("CartTable");

      arr[index] = {
        ...arr[index],
      };

      if (type == "logo") {
        arr[index].no_of_colors = noOfColors;
      }

      if (type == "color") {
        arr[index].product_sku = colors;
        arr[index].product_sku_id = colors?.id;
      }

      let qty_val;

      if (type == "decrement") {
        qty_val = +qty - 1;
      }
      if (type == "increment") {
        qty_val = +qty + 1;
      }

      let prices_pack = {
        25: 0,
        50: 0,
        100: 0,
        150: 0,
        250: 0,
        500: 0,
        1000: 0,
      };

      if (qty_val) {
        arr?.map((item, ind) => {
          let pack_sizes = [25, 50, 100, 150, 250, 500, 1000];
          let finder = item?.product;
          let quantity_1;

          if (item?.product?.id == id) {
            quantity_1 = qty_val;
          } else {
            quantity_1 = +item?.qty;
          }

          pack_sizes.map((price) => {
            let quantity = +quantity_1 * +price;

            if (+quantity >= 1 && +quantity <= 25) {
              prices_pack[price] += finder?.[`for_${25}`] * +quantity_1;
            } else if (+quantity > 25 && +quantity <= 50) {
              prices_pack[price] += finder?.[`for_${50}`] * +quantity_1;
            } else if (+quantity > 50 && +quantity <= 100) {
              prices_pack[price] += finder?.[`for_${100}`] * +quantity_1;
            } else if (+quantity > 100 && +quantity <= 150) {
              prices_pack[price] += finder?.[`for_${150}`] * +quantity_1;
            } else if (+quantity > 150 && +quantity <= 250) {
              prices_pack[price] += finder?.[`for_${250}`] * +quantity_1;
            } else if (+quantity > 250 && +quantity <= 500) {
              prices_pack[price] += finder?.[`for_${500}`] * +quantity_1;
            } else if (+quantity > 500 && +quantity <= 1000) {
              prices_pack[price] += finder?.[`for_${1000}`] * +quantity_1;
            } else if (+quantity > 1000) {
              prices_pack[price] += finder?.[`for_${1000}`] * +quantity_1;
            }

            if (+pack_no >= 1 && +pack_no <= 25) {
              pack_value = 25;
            } else if (+pack_no > 25 && +pack_no <= 50) {
              pack_value = 50;
            } else if (+pack_no > 50 && +pack_no <= 100) {
              pack_value = 100;
            } else if (+pack_no > 100 && +pack_no <= 150) {
              pack_value = 150;
            } else if (+pack_no > 150 && +pack_no <= 250) {
              pack_value = 250;
            } else if (+pack_no > 250 && +pack_no <= 500) {
              pack_value = 500;
            } else if (+pack_no > 500 && +pack_no <= 1000) {
              pack_value = 1000;
            } else if (+pack_no > 1000) {
              pack_value = 1000;
            }
          });
        });

        arr[index] = {
          ...arr[index],
          qty: qty_val,
        };
      }

      // console.log("prices_pack", prices_pack);
      // console.log("qty_val", qty_val);
      // console.log("pack_value", pack_value);

      let final = {
        ...obj,
        cartDetail: arr,
      };

      if (type == "decrement" || type == "increment") {
        final.pack_prices = prices_pack;
        final.price_per_pack = Number(+prices_pack?.[`${pack_value}`]).toFixed(
          2
        );
        final.total_amount = Number(
          +prices_pack?.[`${pack_value}`] * +pack_no
        ).toFixed(2);
      }

      let request = stored.put(final, "individual");

      request.onsuccess = function () {
        console.log("success!");
        getDB();
      };

      request.onerror = function () {
        console.log(request.error);
      };

      // console.log("final", final);
    };
  };

  // DELETE LOCAL CART
  const deleteLocalCart = (id) => {
    let tx = dataBase.transaction("CartTable", "readonly");
    let store = tx.objectStore("CartTable");

    let data = store.get("individual");
    data.onsuccess = (eve) => {
      let cart_data = eve.target.result;

      // DELETE CART
      let obj = { ...cart_data };
      let arr = [...obj?.cartDetail];
      let index = arr.findIndex((i) => i.product_id == id);
      arr.splice(index, 1);

      let txs = dataBase.transaction("CartTable", "readwrite");
      let stored = txs.objectStore("CartTable");

      let packs = [];
      const temp = [];
      let pack_value = obj?.no_of_packs;

      let prices_pack = {
        25: 0,
        50: 0,
        100: 0,
        150: 0,
        250: 0,
        500: 0,
        1000: 0,
      };

      arr.map((item, ind) => {
        let pack_sizes = [25, 50, 100, 150, 250, 500, 1000];
        let quantity_1 = +item?.qty;
        temp.push(item?.product?.id);
        let finder = item?.product;

        pack_sizes.map((price) => {
          let quantity = +quantity_1 * +price;

          if (+quantity >= 1 && +quantity <= 25) {
            prices_pack[price] += finder?.[`for_${25}`] * +quantity_1;
          } else if (+quantity > 25 && +quantity <= 50) {
            prices_pack[price] += finder?.[`for_${50}`] * +quantity_1;
          } else if (+quantity > 50 && +quantity <= 100) {
            prices_pack[price] += finder?.[`for_${100}`] * +quantity_1;
          } else if (+quantity > 100 && +quantity <= 150) {
            prices_pack[price] += finder?.[`for_${150}`] * +quantity_1;
          } else if (+quantity > 150 && +quantity <= 250) {
            prices_pack[price] += finder?.[`for_${250}`] * +quantity_1;
          } else if (+quantity > 250 && +quantity <= 500) {
            prices_pack[price] += finder?.[`for_${500}`] * +quantity_1;
          } else if (+quantity > 500 && +quantity <= 1000) {
            prices_pack[price] += finder?.[`for_${1000}`] * +quantity_1;
          } else if (+quantity > 1000) {
            prices_pack[price] += finder?.[`for_${1000}`] * +quantity_1;
          }
        });

        packs.push({
          25: finder?.for_25,
          50: finder?.for_50,
          100: finder?.for_100,
          150: finder?.for_150,
          250: finder?.for_250,
          500: finder?.for_500,
          1000: finder?.for_1000,
        });
      });

      const result = packs?.reduce((accum, current) => {
        Object.entries(current).forEach(([key, value]) => {
          accum[key] = +accum[key] + +value || +value;
        });
        return {
          ...accum,
        };
      }, {});

      let qtyPack;

      if (+pack_value >= 1 && +pack_value <= 25) {
        qtyPack = +prices_pack?.["25"];
      } else if (+pack_value > 25 && +pack_value <= 50) {
        qtyPack = +prices_pack?.["50"];
      } else if (+pack_value > 50 && +pack_value <= 100) {
        qtyPack = +prices_pack?.["100"];
      } else if (+pack_value > 100 && +pack_value <= 150) {
        qtyPack = +prices_pack?.["150"];
      } else if (+pack_value > 150 && +pack_value <= 250) {
        qtyPack = +prices_pack?.["250"];
      } else if (+pack_value > 250 && +pack_value <= 500) {
        qtyPack = +prices_pack?.["500"];
      } else if (+pack_value > 500 && +pack_value <= 1000) {
        qtyPack = +prices_pack?.["1000"];
      } else if (+pack_value > 1000) {
        qtyPack = +prices_pack?.["1000"];
      }

      let final = {
        no_of_packs: +pack_value,
        price_per_pack: Number(+qtyPack).toFixed(2),
        total_amount: Number(+qtyPack * +pack_value).toFixed(2),
        cartDetail: arr,
        pack_prices: prices_pack,
      };

      let request = stored.put(final, "individual");

      request.onsuccess = function () {
        console.log("success!");
        let obj = { ...cartData };
        delete obj[id];
        setCartData(obj);
        setTick(temp);
        setShowPopup(false);
        getDB();
      };

      request.onerror = function () {
        console.log(request.error);
      };
    };

    data.onerror = function () {
      console.log(data.error);
    };
  };

  // PACK QTY CHANGE AND CUSTOM QTY CHANGE
  const customQtyLocal = (no_of_packs) => {
    setQtyBtn(true);
    const no_of_pack = no_of_packs ? no_of_packs : noOfPack;

    if (no_of_pack?.length == 0) {
      setNoOfPackErr(true);
    } else {
      let tx = dataBase.transaction("CartTable", "readonly");
      let store = tx.objectStore("CartTable");

      let data = store.get("individual");
      data.onsuccess = (eve) => {
        let txs = dataBase.transaction("CartTable", "readwrite");
        let stored = txs.objectStore("CartTable");
        let cart_data = eve.target.result;
        let obj = { ...cart_data };
        let result = obj?.pack_prices;

        let qtyPack;

        if (+no_of_pack >= 1 && +no_of_pack <= 25) {
          qtyPack = +result?.["25"];
        } else if (+no_of_pack > 25 && +no_of_pack <= 50) {
          qtyPack = +result?.["50"];
        } else if (+no_of_pack > 50 && +no_of_pack <= 100) {
          qtyPack = +result?.["100"];
        } else if (+no_of_pack > 100 && +no_of_pack <= 150) {
          qtyPack = +result?.["150"];
        } else if (+no_of_pack > 150 && +no_of_pack <= 250) {
          qtyPack = +result?.["250"];
        } else if (+no_of_pack > 250 && +no_of_pack <= 500) {
          qtyPack = +result?.["500"];
        } else if (+no_of_pack > 500 && +no_of_pack <= 1000) {
          qtyPack = +result?.["1000"];
        } else if (+no_of_pack > 1000) {
          qtyPack = +result?.["1000"];
        }

        let final = {
          ...obj,
          no_of_packs: +no_of_pack,
          price_per_pack: Number(+qtyPack).toFixed(2),
          total_amount: Number(+qtyPack * +no_of_pack).toFixed(2),
        };

        let request = stored.put(final, "individual");

        request.onsuccess = function () {
          getDB();
          setNoOfPack("");
          setNoOfPackErr(false);
        };

        request.onerror = function () {
          console.log(request.error);
        };
      };
    }
  };

  useEffect(() => {
    if (Token) {
      getCartApi();
    } else {
      getDB();
    }
  }, []);

  useEffect(() => {
    if (cortList?.length == 0) {
      setNo_of_pack(25);
    }
  }, [cortList]);

  // FOR AMOUNT COMMA ADD
  const commaValue = (price) => {
    let amount = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return price;
  };

  // console.log("viewData", budgetPack);

  return (
    <div className="d-flex mock-left-req">
      <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-70 px-2 px-lg-2 left-mock-req">
        {/* <div className="bread-crumb d-flex ac-jc bg-lt-blue">
          <Link to="" className="primary1">
            Build Custom
          </Link>
          <ArrowForwardIosIcon className="primary" />
          <Link to="" className="primary">
            Complete Mockups Request
          </Link>
        </div> */}
        {conatctDetail ? (
          <TellProject
            showContactHandler={showContactHandler}
            handleContinue={handleContinue}
            date={date}
            setDate={setDate}
            dateErr={dateErr}
            comments={comments}
            setComments={setComments}
            commentsErr={commentsErr}
            primaryLogo={primaryLogo}
            primaryLogoErr={primaryLogoErr}
            setPrimaryLogo={setPrimaryLogo}
            secondryLogo={secondryLogo}
            secondryLogoErr={secondryLogoErr}
            setSecondryLogo={setSecondryLogo}
            manyPacks={manyPacks}
            manyPacksErr={manyPacksErr}
            setManyPacks={setManyPacks}
            budgetPack={budgetPack}
            setBugetPack={setBugetPack}
            budgetPackErr={budgetPackErr}
            submitHandle={submitHandle}
            btn={btn}
          />
        ) : (
          <ContactDetails
            showContactHandler={showContactHandler}
            handleContinue={handleContinue}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            number={number}
            setNumber={setNumber}
            organization={organization}
            setOrganization={setOrganization}
            firstNameErr={firstNameErr}
            lastNameErr={lastNameErr}
            emailErr={emailErr}
            numberErr={numberErr}
            organizationErr={organizationErr}
            submitHandle={submitHandle}
            btn={btn}
          />
        )}
      </div>
      <MockReqCart
        pack="build"
        tick={tick}
        categoryList={categoryList}
        addProductHandler={addProductHandler}
        cortList={cortList}
        priceList={priceList}
        setShowPopup={showPopupHander}
        deleteCart={deleteCart}
        userCartID={userCartID}
        getProductView={getProductView}
        packs={pack}
        totalPrice={totalPrice}
        totalPerPrice={totalPerPrice}
        deleteBtn={deleteBtn}
        customQty={customQty}
        noOfPack={noOfPack}
        setNoOfPack={setNoOfPack}
        noOfPackErr={noOfPackErr}
        setShowOption={setShowOption}
        showoption={showoption}
      />

      {showPopup && (
        <ProductPopupComponent
          type={"induvial"}
          method={"final"}
          varients={varients}
          setSelectedColorId={setSelectedColorId}
          selectedColorId={selectedColorId}
          setSelectColorId={setSelectColorId}
          selectColorId={selectColorId}
          showPopupHander={showPopupHander}
          viewList={viewList}
          imagesid={imagesid}
          setSelectedIds={setSelectedIds}
          selectedIds={selectedIds}
          count={count}
          setCount={setCount}
          productPrice={productPrice}
          setTick={setTick}
          packsPrice={packsPrice}
          getAddToCart={getAddToCart}
          setColorCount={setColorCount}
          colorcount={colorcount}
          bigImg={bigImg}
          setBigImg={setBigImg}
          setMaterial={setMaterial}
          material={material}
          setSize={setSize}
          size={size}
          setStyle={setStyle}
          style={style}
          pack={pack}
          calculations={calculations}
          setCartData={setCartData}
          cartData={cartData}
          userCartID={userCartID}
          deleteCart={deleteCart}
          cartBtn={cartBtn}
          deleteBtn={deleteBtn}
          varientsNew={varientsNew}
          updateLocalCart={updateLocalCart}
          qtyBtn={qtyBtn}
        />
      )}
    </div>
  );
};

export default MockupRequest;

// @ts-nocheck
import React from "react";
import {
  blank,
  buildImg,
  bulkImg,
  kit1,
  kit2,
  kit3,
  letsWorkIcon,
  logist,
  presetImg,
} from "../assets/img";
import { useNavigate } from "react-router-dom";

const LetsStart = () => {
  const Token = localStorage.getItem("token");
  const navigate = useNavigate();
  return (
    <div className="w-xxl-100  mt-4 mt-lg-4">
      <h4 className="sect-title text-center">Let’s get down to business.</h4>
      {/* <p className="parag text-center">Select one of the options below:</p> */}
      <div className="d-flex flex-wrap flex-column flex-sm-row ac-jc m-auto">
        <div className="d-flex flex-column justify-content-center p-3 text-center position-relative wi-100 w-md-50 w-lg-25">
          <div className=" let-start-box">
            <div className="mt-2 mt-md-4 mt-xl-5">
              <img
                //  src={kit1}
                src={buildImg}
                className="work1 w-50"
                alt=""
              />
            </div>

            <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20 mb-1 mt-2 mt-lg-3">
              Design Your Kit
            </h6>
            <button
              onClick={() => {
                // navigate("/dashboard/lets-start/build-pack");
                navigate("/products/build-pack");
              }}
              className="mx-auto mb-3 mt-4 mb-lg-5 get-start-btn border-0 w-xs-50 w-sm-45 w-lg-70  w-xl-60 w-xxl-55 bg-primar"
            >
              Build My Own Kit
            </button>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center p-3 text-center position-relative wi-100 w-md-50 w-lg-25">
          <div className=" let-start-box">
            <div className="mt-2 mt-md-4 mt-xl-5">
              <img
                //  src={kit2}
                src={presetImg}
                className="work1 w-50 "
                alt=""
              />
            </div>

            <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20 mb-1 mt-2 mt-lg-3 ">
              Preset Kits
            </h6>
            <button
              onClick={() => {
                // navigate("/dashboard/lets-start/build-pack");
                navigate("/products/preset-pack");
              }}
              className="mx-auto mb-3 mt-4 mb-lg-5 get-start-btn border-0 w-xs-50 w-sm-45 w-lg-70  w-xl-60 w-xxl-55 bg-primar"
            >
              Explore Preset Kits
            </button>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center p-3 text-center position-relative wi-100 w-md-50 w-lg-25">
          <div className=" let-start-box">
            <div className="mt-2 mt-md-4 mt-xl-5">
              <img
                // src={kit3}
                src={bulkImg}
                className="work1 w-50"
                alt=""
              />
            </div>

            <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20 mb-1 mt-2 mt-lg-3 ">
              Bulk Merchandise
            </h6>
            <button
              onClick={() => {
                // navigate("/dashboard/lets-start/build-pack");
                navigate("/products/bulk-pack");
              }}
              className="mx-auto mb-3 mt-4 mb-lg-5 get-start-btn border-0 w-xs-50 w-sm-45 w-lg-70  w-xl-60 w-xxl-55 bg-primar"
            >
              Explore Bulk Merch
            </button>
          </div>
        </div>
        {/* <div className="d-flex flex-column justify-content-center p-3 text-center position-relative wi-100 w-md-50 w-lg-25">
          <div className=" let-start-box">
            <div className="mt-2 mt-md-4 mt-xl-5">
              <img src={blank} className="work1 w-50" alt="" />
            </div>

            <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20 mb-1 mt-2 mt-lg-3 ">
              Buy a Blank
            </h6>
            <button
              onClick={() => {
                if (Token) {
                  navigate("/dashboard/products/e-commerce");
                } else {
                  navigate("/login");
                }
              }}
              className="mx-auto mb-3 mt-4 mb-lg-5 get-start-btn border-0 w-xs-50 w-sm-45 w-lg-70  w-xl-60 w-xxl-55 bg-primar"
            >
              Build my own kit
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default LetsStart;

// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  closeIcon,
  cloudUpload,
  noLogo,
  product,
  product2,
  uploadIcon,
} from "../../assets/img";
import { productDetailImg } from "../../redux/api/constants";
import { useViewProjectProductStatusMutation } from "../../redux/api/api";
import { image_slider } from "../../redux/api/DummyJson";
import ImageGallery from "react-image-gallery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

// import CloseIcon from "@mui/icons-material/Close";

const ProductPopupComponentDecline = ({
  showPopupHander,
  type,
  list,
  closing_date,
  setclosing_date,
  pendingitem,
  getProduct,
  showModal,
}) => {
  const [uploadImage, setUploadImage] = useState();
  const [location, setLocation] = useState("");
  const [dimension, setDimension] = useState("");
  const [notes, setNotes] = useState("");
  const [locationErr, setLocationErr] = useState(false);
  const [dimensionErr, setDimensionErr] = useState(false);
  const [notesErr, setNotesErr] = useState(false);
  const [uploadErr, setUploadImageErr] = useState(false);
  const proImgRef = useRef();
  const [artwork, setArtwork] = useState("");
  const [artworkInd, setArtworkInd] = useState(1);
  const [artwrk, setArtWork] = useState({});

  //rtk
  const [statusChangeApi] = useViewProjectProductStatusMutation();

  const fileChange = (e) => {
    setUploadImage(e.target.files[0]);
  };
  const handelContinue = () => {
    if (
      uploadImage == undefined ||
      // location?.length == 0 ||
      // dimension?.length == 0 ||
      notes?.length == 0
    ) {
      setUploadImageErr(true);
      // setLocationErr(true);
      // setDimensionErr(true);
      setNotesErr(true);
    } else {
      const formdata = new FormData();
      formdata.append("project_detail_id", pendingitem?.id);
      formdata.append("artwork_status", "4");
      // formdata.append("location", location);
      // formdata.append("dimension", dimension);
      formdata.append("user_comment", notes);
      formdata.append("project_artwork_id", artwork);

      if (uploadImage?.name) {
        formdata.append("user_logo", uploadImage);
      }

      statusChangeApi(formdata)
        .unwrap()
        .then((res) => {
          console.log(res, "res");
          getProduct();
          showPopupHander();
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };

  const artworkSelector = (ids) => {
    let finder = pendingitem?.artworks?.find((item) => item.id == ids);
    setArtwork(ids);
    setArtWork(finder);
    setUploadImage(finder);

    let findIndex = pendingitem?.artworks?.findIndex((item) => item.id == ids);
    setArtworkInd(findIndex + 1);
  };

  useEffect(() => {
    setArtwork(pendingitem?.artworks?.[0]?.id);
    setArtWork(pendingitem?.artworks?.[0]);
  }, []);

  return (
    <div className="pro-model-model">
      <Modal
        show={showModal}
        onHide={showPopupHander}
        className="show_mod_model"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="mb-3">
          <div className="d-flex ac-je">
            <button
              onClick={showPopupHander}
              className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
            >
              <img
                src={closeIcon}
                alt=""
                style={{
                  width: "25px",
                  height: "25px",
                  border: "2px solid #06679c",
                  borderRadius: "100%",
                  padding: "2px",
                }}
              />
            </button>
          </div>
          <div className="d-flex flex-column as-jc px-3 mx-auto px-3 px-lg-5 w-100 h-100 mt-4">
            <h6 className="mb-2  f4 fs-xs-20 fs-sm-24 fs-lg-26 fs-xl-28">
              Artwork {artworkInd}
            </h6>
            <div className="w-100 d-flex  flex-column flex-md-row">
              <div className="w-xs-100 w-md-50 px-2">
                <div className="d-flex flex-wrap tl-log-upload-box ac-jb mt-2 mt-lg-2">
                  <p className="parag mb-2">
                    {pendingitem?.product?.name
                      ? pendingitem?.product?.name
                      : ""}
                  </p>
                  <input
                    ref={proImgRef}
                    className="d-none"
                    type={"file"}
                    onChange={(e) => setUploadImage(e.target.files[0])}
                  />
                  <div className="">
                    {uploadImage ? (
                      <div
                        className="prev-img"
                        onClick={() => {
                          proImgRef?.current?.click();
                        }}
                        role={"button"}
                      >
                        <img
                          src={
                            uploadImage?.name
                              ? URL.createObjectURL(uploadImage)
                              : uploadImage?.image_url
                          }
                          alt=""
                        />
                      </div>
                    ) : (
                      <div
                        className="log-upload-box position-relative d-flex ac-jc mt-3 mb-3"
                        onClick={() => {
                          proImgRef?.current?.click();
                        }}
                        role={"button"}
                      >
                        <p className="primary1 fs-14 fs-sm-15 fs-md-17 fs-xl-18">
                          Primary Logo
                        </p>
                        <img src={cloudUpload} className="" alt="" />
                        <p className="text-dark text-center fs-13 fs-md-14">
                          Drag and drop to upload or Click <br />
                          here to upload
                        </p>
                      </div>
                    )}

                    <div
                      className="d-flex ac  mt-4 mob-carous-pop"
                      style={{ width: "300px", overflow: "scroll" }}
                    >
                      {pendingitem?.artworks?.map((item, ind) => {
                        return (
                          <div
                            className="down_imgs m-1"
                            key={ind}
                            role={"button"}
                            onClick={() => artworkSelector(item?.id)}
                            style={
                              artwrk?.id == item?.id
                                ? { border: "1px solid black" }
                                : { border: "none" }
                            }
                          >
                            <img
                              src={item.image_url ? item.image_url : noLogo}
                              style={{
                                padding: "10px",
                                height: "100px",
                                width: "100px",
                                objectFit: "cover",
                                borderRadius: "15px",
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {uploadImage == undefined && uploadErr && (
                  <div className="d-flex gap-1 mt-2">
                    {" "}
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select or Upload image</p>
                  </div>
                )}
              </div>
              <div className="w-xs-100 w-md-50 px-2">
                <div className="ac-jb mt-3 mt-lg-4 pop-input">
                  <p className="f3 primary1 fs-16 fs-md-18 fs-lg-19 mb-0 mt-3">
                    Artworks
                  </p>
                  <select
                    className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                    onChange={(e) => artworkSelector(e.target.value)}
                    value={artwork}
                  >
                    {pendingitem?.artworks?.length > 0 ? (
                      pendingitem?.artworks?.map((item, ind) => {
                        return (
                          <option
                            style={{ textAlign: "center" }}
                            value={item?.id}
                          >{`Artwork ${ind + 1}`}</option>
                        );
                      })
                    ) : (
                      <option style={{ textAlign: "center" }} value={""}>
                        Select Artwork
                      </option>
                    )}
                  </select>
                </div>
                <div className="ac-jb mt-3 mt-lg-4 pop-input">
                  <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                    Decorative Method
                  </p>
                  <input
                    className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                    value={
                      artwrk?.decorative_method_id?.name
                        ? artwrk?.decorative_method_id?.name
                        : "-"
                    }
                  />
                </div>
                <div className="ac-jb mt-3 mt-lg-4 pop-input">
                  <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                    Location Name
                  </p>
                  <input
                    className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                    value={artwrk?.logo_name ? artwrk?.logo_name : "-"}
                  />
                </div>
                <div className="d-flex justify-content-between mt-3 mt-lg-4 pop-input">
                  <div>
                    <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                      Dimensions
                    </p>
                    <input
                      className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                      placeholder="L*B"
                      value={`${
                        artwrk?.logo_length ? artwrk?.logo_length : ""
                      }*${artwrk?.logo_breadth ? artwrk?.logo_breadth : "-"}`}
                    />
                  </div>
                  <div>
                    <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                      Logo Colour
                    </p>
                    <input
                      className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                      value={artwrk?.logo_color ? artwrk?.logo_color : "-"}
                    />
                  </div>
                </div>
                {/* <div className="ac-jb mt-3 mt-lg-4 pop-input">
                  <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                    Location Name
                  </p>
                  <input
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder="Specify the placement of your artwork"
                    className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                    // required
                  />
                </div>
                {location?.length == 0 && locationErr && (
                  <div className="d-flex gap-1 mt-2">
                    {" "}
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Location</p>
                  </div>
                )}
                <div className="ac-jb mt-2 mt-lg-2 pop-input">
                  <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                    Dimensions
                  </p>
                  <input
                    type="text"
                    placeholder="W 2.3 X H 4.5"
                    value={dimension}
                    onChange={(e) => setDimension(e.target.value)}
                    className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                    // required
                  />
                </div>
                {dimension?.length == 0 && dimensionErr && (
                  <div className="d-flex gap-1 mt-2">
                    {" "}
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Dimension</p>
                  </div>
                )} */}
              </div>
            </div>
            <div className="ac-jb mt-2  pop-input w-100">
              <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                Specify Details
                {/* <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props} id="button-tooltip">
                      <p className="text-capitalize text-start f4 fs-15">
                        I would like to change the logo I want the logo to be
                        centered Can you show me a different option
                      </p>
                    </Tooltip>
                  )}
                >
                  <span>
                    <ErrorIcon className="" />
                  </span>
                </OverlayTrigger> */}
                <span className="icon-pop1 ms-1 position-relative">
                  <ErrorOutlineIcon
                    style={{ cursor: "pointer" }}
                    className="f3 fs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-lg-15 mb-0 pb-0 primary1 mt-0 text-center"
                  />
                  <div className="icon-pop2 position-absolute justify-content-center align-items-center d-flex">
                    <p className="f3 mb-0 pb-0 text-dark mt-0 text-center px-3">
                      I would like to change the logo I want the logo to be
                      centered Can you show me a different option
                    </p>
                  </div>
                </span>
              </p>
              <input
                type="text"
                placeholder="Notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 mt-1"
                // required
              />
            </div>
            {notes?.length == 0 && notesErr && (
              <div className="d-flex gap-1 mt-2">
                {" "}
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Notes</p>
              </div>
            )}
            <button
              className="get-start-btn border-0 mt-4 mx-auto d-flex py-2"
              onClick={() => handelContinue()}
            >
              Continue
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProductPopupComponentDecline;
